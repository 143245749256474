.edit-blog-icons {
    opacity: .4;
    position: absolute;
    top: 0;
    left: -10px;
}
[type="checkbox"] {
    width: 18px;
}
.ck-editor__main ol, .ck-editor__main ul {
    padding-left: 10px;
}
.ck-editor__main ul li {
    list-style: outside!important;
}
.ck-editor__main ol li {
    list-style: auto!important;
}
.MuiFormLabel-root {
    display: none!important;
}
.ck-editor__editable p {
    margin: 0.1em 0!important;

}
.ck-editor__main > div {
    min-height: 40px;
}
.ck-editor__main {
    max-height: 400px;
    overflow: auto;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}
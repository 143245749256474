.jobs-title {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.coloring {
    font-weight: bold;
}
.coloring[data-color="good_desc"],
.coloring[data-color="good"] {
    background: rgba(144, 238, 144, 0.27);
}

.coloring[data-color="bad_desc"],
.coloring[data-color="bad"] {
    background: rgba(238, 144, 152, 0.27);
}
.coloring[data-color="mid_desc"],
.coloring[data-color="mid"] {
    background: rgba(238, 225, 144, 0.27);
}
.coloring[data-color="super_good"] {
    background: rgba(0, 112, 0, 0.7);
    color: white;
    padding: 2px 7px;

}

.MuiAutocomplete-tag {
    margin: 1px;
    height: auto!important;
}
pre {
    white-space: pre-wrap;
}

pre p {
    padding: 0;
    margin: 0;
}
pre li {
    display: block;
    height: auto;
    line-height: 1.2;
    list-style: gurmukhi;
    margin: 0;
    padding: 0;
}
.tag-item {
    border: 1px solid rgba(128, 128, 128, 0.55);
    display: inline-block;
    padding: 1px 2px;
    margin: 3px;
}

.item-job {
    padding: 5px 0;
    margin-bottom: 5px;
    border: 1px solid #ececec;

}
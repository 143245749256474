
.users-apply.grey {
    opacity: .2;
}
.users-apply-types {
    padding: 3px;
    display: inline-block;
}
.users-apply {
    padding: 10px;
    display: inline-block;
    cursor: pointer;
}
.user-apply2 {
    max-height: 250px;
    overflow: auto;
}
.user-apply {
    padding: 3px;
}
.selected,
.usersWrap .selected {
    background: rgba(144, 238, 144, 0.52);
}
[data-status='error'].msg-item {
    background: rgba(255, 0, 0, 0.24);
}
.msg-item {
    padding: 5px;
    word-break: break-all;
    max-width: 80%;
    border-radius: 10px;
    margin-left: 2%;
    border: 1px solid lightgrey;
    margin-bottom: 5px;
    margin-top: 5px;
}
.my-msg {
    text-align: right;
    margin-left: 18%;
}

.msgs-wrap {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}
.react-wrap:hover {
    opacity: 1;
}
.react-wrap {
    opacity: .3;
}
.ellipseText {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.skill-item {
    padding: 2px;
    font-size: 10px;
    border: 1px solid grey;
    display: inline-block;
}

.skill-item.selected {
    background: lightblue;
    /*border: 1px solid green;*/
}
.selectedUserWrap {
    border: 1px solid rgba(128, 128, 128, 0.32);
    padding: 10px;
    box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.72);
}

.cvsWrap {
    max-height: 300px;
    overflow: auto;
}
.loading-msgs {
    opacity: .4;
}
.input-ch-wrap > div {
    padding-bottom: 10px;
}
[data-args-custom] {
    display: inline-block;
}

[data-args-custom-good] {
    background: rgba(0, 128, 0, 0.51);
}
[data-args-custom-mid] {
    background: rgba(255, 255, 0, 0.58);
}
[data-args-custom-error], [data-args-custom-bad] {
    background: rgba(255, 0, 0, 0.53);
}
[data-args-custom-search] {
    background: rgba(0, 140, 255, 0.53);
}

.autoTitle {
    max-height: 100px;
    overflow: auto;
}

.descJob {
    white-space: pre-line;
}

.jobs-wrap {
    max-height: 800px;
    overflow-y: auto;
}

.zz, .MuiAutocomplete-popper {
    position: absolute;
    z-index: 100000000000!important;
}


[data-set-tags="unactive"], [data-apply='unactive'] {
    opacity: .5;
}
.wrongApply, .wrongApply pre {
    background: rgba(255, 0, 0, 0.57);
}

[data-user-group-key='applied'] [data-problems] {
    display: none!important;
}
[data-user-group-key='applied'].o5 {
    opacity: 1!important;
}


[data-ind-30="0"] {
    opacity: .5;
}
[data-score="great"][data-ind-30="1"]  {
    background: #aeaee1;
    font-weight: bold;
}
[data-score="mid"][data-ind-30="1"]  {
    background: rgba(174, 174, 225, 0.34);
}
[data-score="great"][data-ind-30="0"]  {
    background: #e1aeae;
    font-weight: bold;
}
[data-score="mid"][data-ind-30="0"]  {
    background: rgba(225, 174, 174, 0.49);
}
.rel {
    position: relative;
}
.user-apply-count2 {
    margin-right: 3px;
    font-weight: bold;
    font-size: 12px;
}
.user-apply-count {
    font-size: 12px;
    float: right;
    position: absolute;
    top: -5px;
    right: -5px;
    background: white;
    padding: 0 3px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}
.userwrapChat:hover {
    box-shadow: 5px 5px 5px grey;
}
.userwrapChat {
    border: 1px solid lightgrey;
    transition: all .4s ease;
    margin: 10px 0;
    padding: 15px;
}

.problematItem {
    font-size: 12px;
    border: 1px solid grey;
    display: inline-block;
    padding: 0 4px;
    font-weight: bold;
    position: relative;
}

.forPending {
    display: none;
}
.pending .forPending {
    display: block;
}
.pending {
    opacity: .4;
}